

























































import { mapActions } from 'vuex'
import { required, email, maxChars } from '@/calendesk/forms/validators'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '../../DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import mixins from 'vue-typed-mixins'
export default mixins(DialogMixins).extend({
  name: 'ForgottenPasswordDialogForm',
  data: () => ({
    error: null as string | null,
    loading: false,
    valid: false,
    form: {
      email: null
    },
    rules: {
      required,
      email,
      maxChars
    }
  }),
  methods: {
    ...mapActions({
      forgottenPassword: 'user/forgottenPassword'
    }),
    handleSubmit () {
      if (!this.valid) {
        return
      }
      this.error = null
      this.loading = true
      this.$emit('loading', this.loading)
      this.forgottenPassword(this.form.email)
        .then(() => {
          this.openDialog(new Dialog(
            true,
            DialogTypes.CONFIRMATION_DIALOG,
            this.draftConfiguration.forgotten_password_modal_confirmation_configuration.wb_forgotten_password_modal_confirmation_title__text__,
            DialogSize.SMALL,
            false,
            'center',
            {
              subtitle: this.draftConfiguration.forgotten_password_modal_confirmation_configuration.wb_forgotten_password_modal_confirmation_subtitle__long_text__
            }))
        })
        .catch((error) => {
          if (error?.response?.data?.code === 'TOO_MANY_REQUESTS') {
            this.error = this.$trans('too_many_requests')
          } else {
            this.error = this.$trans('error_occurred')
          }
        })
        .finally(() => {
          this.loading = false
          this.$emit('loading', this.loading)
        })
    }
  }
})
